import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import md5 from "md5";
import localization from "moment/locale/th";
import { toBuddhistYear } from "./buddhist-year";
import { DEFAULT_TARGET_LANGUAGE, getLocalStorage } from "./localstorage";
import qs from "qs";
import styles from "./helper.module.scss";
import tinycolor from "tinycolor2";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import _ from "lodash";

// import { MathJax, MathJaxContext } from "better-react-mathjax";
import MathJax from "react-mathjax-preview";
import { Skeleton } from "antd";

// import { identifierLocalStorage } from './localstorage'
// import { deviceDetect } from 'react-device-detect'
// import { setAuthorizationToken } from 'api/kiddy-connector'
moment.updateLocale("th", localization);

export const idGenerate = (text) => {
  return md5(text);
};

export const debounce = (func, wait, immediate) => {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

export const setCookie = (cname, value, expiredHour = 0) => {
  let expires = "";
  if (expiredHour) {
    const date = new Date();
    date.setTime(date.getTime() + expiredHour * 60 * 60 * 1000);
    expires = `expires=${date.toUTCString()};`;
  }
  document.cookie = `${cname}=${value};${expires}path=/`; // eslint-disable-line
};

export const deleteCookie = (cname) => {
  document.cookie = `${cname}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/`; // eslint-disable-line
};

export const getCookie = (cname) => {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie); // eslint-disable-line
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
};

export const isEmptyObj = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const IsOverCurrentTime = (time) =>
  time - moment().valueOf() / 1000 >= 0;
export const NotOverCurrentTime = (time) =>
  time - moment().valueOf() / 1000 <= 0;
// new Format
// D MMM YY,  HH:mm น.  // date and Time
// D MMM YY // monthYear
// export const dateFromTimeStamp = (ts) => {
//   const dt = moment(ts * 1000)
//   return dt.isValid() ? toBuddhistYear(dt, 'DD/MM/YYYY') : '-'
// }
// export const dateFromTimeStampThai = (ts) => {
//   const dt = moment(ts * 1000)
//   if (DEFAULT_TARGET_LANGUAGE === 'en') {
//     moment.locale('en')
//     return dt.isValid() ? dt.format('D MMM YYYY') : '-'
//   }
//   return dt.isValid() ? toBuddhistYear(dt, 'D MMM YYYY') : '-'
// }
// export const dateAndTimeFromTimeStamp = (ts) => {
//   const dt = moment(ts)
//   return dt.isValid() ? toBuddhistYear(dt, 'DD/MMM/YYYY, HH:mm') : '-'
// }

// export const dateAndTimeThai = (ts) => {
//   const dt = moment(ts)
//   return dt.isValid() ? toBuddhistYear(dt, 'D MMM YYYY, HH:mm') : '-'
// }

// export const dateAndTimeStamp = (ts) => {
//   const dt = moment(ts)
//   return dt.isValid() ? toBuddhistYear(dt, 'D MMM YYYY HH:mm') : '-'
// }

// export const dateAndTimeFull = (ts) => {
//   const dt = moment(ts)
//   return dt.isValid() ? toBuddhistYear(dt, 'วันที่ D MMM YYYY เวลา HH:mm น.') : '-'
// }
// init
export const dateMonthYear = (ts) => {
  const dt = moment(ts);
  if (DEFAULT_TARGET_LANGUAGE === "en") {
    moment.locale("en");
    return dt.isValid() ? dt.format("D MMM YYYY") : "-";
  }
  return dt.isValid() ? toBuddhistYear(dt, "D MMM YYYY") : "-";
};
export const timeHHMM = (ts) => {
  const dt = moment(ts);
  return dt.isValid() ? toBuddhistYear(dt, "HH:mm") : "-"; // timeThai
};
export const dateAndTime = (ts) => {
  return `${dateMonthYear(ts)} ${timeHHMM(ts)}`;
};
// duration date
export const dateDurationFormat = (tsStart, tsEnd) => {
  const dtStart = moment(tsStart);
  const dtEnd = moment(tsEnd);
  if (!dtStart.isValid() || !dtEnd.isValid()) {
    return "-";
  }
  return `${toBuddhistYear(dtStart, "DD MMM ")} - ${toBuddhistYear(
    dtEnd,
    "DD MMM YYYY"
  )}`;
};

export const durationTimeFormat = (tsStart, tsEnd) => {
  const equalDay =
    toBuddhistYear(moment(tsStart), "DD") ===
    toBuddhistYear(moment(tsEnd), "DD");
  const equalMonth =
    toBuddhistYear(moment(tsStart), "MM") ===
    toBuddhistYear(moment(tsEnd), "MM");
  const equalYear =
    toBuddhistYear(moment(tsStart), "YYYY") ===
    toBuddhistYear(moment(tsEnd), "YYYY");
  if (equalYear && equalMonth && equalDay) {
    return `${toBuddhistYear(
      moment(tsStart),
      "DD MMM YYYY kk:mm "
    )}-${toBuddhistYear(moment(tsEnd), " kk:mm ")}`;
  }
  if (equalYear && equalMonth) {
    return `${toBuddhistYear(moment(tsStart), "DD")} - ${toBuddhistYear(
      moment(tsEnd),
      "DD MMM YYYY"
    )}`;
  }
  if (equalYear) {
    return `${toBuddhistYear(moment(tsStart), "DD MMM ")} - ${toBuddhistYear(
      moment(tsEnd),
      " DD MMM YYYY"
    )}`;
  }
  return `${toBuddhistYear(moment(tsStart), "DD MMM YYYY")} - ${toBuddhistYear(
    moment(tsEnd),
    "DD MMM YYYY"
  )}`;
};

export const secoundToMinuts = (secound) => {
  const secounds = parseInt(secound) % 60;
  const minutes = parseInt(secound) / 60;
  return `${convertTime(minutes)}:${convertTime(secounds)}`;
};
const convertTime = (time) => {
  return parseInt(time) === 0 || parseInt(time) < 10
    ? "0" + parseInt(time)
    : parseInt(time);
};

export const isInArray = (array, finder) => -1 !== array.indexOf(finder);

export const cloneArray = (items) =>
  items.map((item) => (Array.isArray(item) ? cloneArray(item) : item));

export const isJwtTokenValid = (jwtToken, refreshToken) => {
  if (!jwtToken) return false;

  // eslint-disable-next-line no-undef
  const jwt = JSON.parse(window.atob(jwtToken.split(".")[1]));

  if (!(jwt && jwt.exp)) return false;

  const expireDate = jwt.exp * 1000;
  // Check expire
  if (expireDate) return true;
  // if (expireDate > Date.now()) {
  //   return expireDate > Date.now()
  // } else if (expireDate < Date.now() && refreshToken) {
  //   return false
  // }
};

export const randomNumberBetween = (min, max) => {
  return Math.random() * (max - min) + min;
};

export const monthTH = (numberMonth) => {
  switch (numberMonth) {
    case "01":
      return "มกราคม";
    case "02":
      return "กุมภาพันธ์";
    case "03":
      return "มีนาคม";
    case "04":
      return "เมษายน";
    case "05":
      return "พฤษภาคม";
    case "06":
      return "มิถุนายน";
    case "07":
      return "กรกฎาคม";
    case "08":
      return "สิงหาคม";
    case "09":
      return "กันยายน";
    case "10":
      return "ตุลาคม";
    case "11":
      return "พฤศจิกายน";
    case "12":
      return "ธันวาคม";
    default:
      return "-";
  }
};

export const encodeBase64 = (str) => {
  // eslint-disable-next-line no-undef
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) =>
      String.fromCharCode("0x" + p1)
    )
  );
};

export const decodeBase64 = (str) => {
  // eslint-disable-next-line no-undef
  return decodeURIComponent(
    atob(str)
      .split("")
      .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
      .join("")
  );
};
export const isEquivalent = (a, b) => {
  // Create arrays of property names
  var aProps = Object.getOwnPropertyNames(a);
  var bProps = Object.getOwnPropertyNames(b);

  // If number of properties is different,
  if (aProps.length !== bProps.length) return false;

  for (var i = 0; i < aProps.length; i++) {
    const propName = aProps[i];
    if (a[propName] !== b[propName]) return false;
  }

  // If we made it this far, objects
  // are considered equivalent
  return true;
};
export const extractSubject = (res, handleChangeSubject, query) => {
  try {
    const data = res.map((g) => ({
      id: g.id,
      text: `${g.name} ${g.info ? g.info : ""}`,
      action: () =>
        handleChangeSubject(query, g.id, `${g.name}${g.info ? g.info : ""}`),
    }));
    let all = [
      {
        text: "ทุกวิชา",
        id: "",
        action: () => handleChangeSubject(query, "", "ทุกวิชา"),
      },
    ];
    return all.concat(data);
  } catch (e) {
    return [];
  }
};

export const isInt = (n) => Number(n) === n && n % 1 === 0;
export const isFloat = (n) => Number(n) === n && n % 1 !== 0;
export const twoDecimal = (n) => n.toFixed(2);
export const parseScore = (n) =>
  isFloat(Number(n)) ? twoDecimal(Number(n)) : Number(n);
export const convertDecimal = (number, type) => {
  if (String(number) === "100") {
    return 100;
  }
  if (String(number) === "0") {
    return 0;
  } else {
    return parseScore(number);
  }
};

export const getCoverExamUrl = (grade, subject) => {
  const mapSubject = {
    MATH: "Math",
    SCI: "Science",
    SOC: "History",
    ENG: "English",
    THAI: "Thai",
    "c6e35b15-53ed-436a-941d-56072f4a2043": "Math1",
    "e7b12659-abc1-4658-8ac9-0650ccaf3ee6": "Math2",
  };
  return `https://sobtidimage.s3-ap-southeast-1.amazonaws.com/kiddy/Cover+Exam/Cover+Exam+-+${
    mapSubject[subject]
  }+${parseInt(grade) < 7 ? `P${grade}` : `M${parseInt(grade) - 6}`}%403x.png`;
};

const IsDidPretest = (pretest) =>
  pretest.isPurchase === true && pretest.doPretest === true;

export const PretestStatus = (pretest, firebase, user) => {
  /**  
  status === 0 ยังไม่เริ่มเปิดให้ทำข้อสอบ
  status === 1 เริ่มเปิดให้ทำข้อสอบ
  status === 2 หลังสิ้นสุดช่วงเวลาการทำข้อสอบ
  isSale = true เลยวันเปิดขายแล้ว, false ยังไม่เปิดขาย
  isPurchase = true ซื้อข้อสอบแล้ว
  practiceTime:off ยังไม่ถึงช่วงเวลาที่อนุญาตให้ทำรอบฝึกซ้อม
  */
  const arry = [];
  const openSaleAndAllowTest = () => {
    arry.push("openSaleGetExam");
    arry.push("openTestingTime");
    if (
      IsDidPretest(pretest) &&
      String(pretest?.havePracticeDate) === "false" &&
      IsOverCurrentTime(pretest?.durationPretest?.end + 0)
    ) {
      arry.push(`practiceTime:off`);
    }
    if (
      IsDidPretest(pretest) &&
      String(pretest?.havePracticeDate) === "true" &&
      IsOverCurrentTime(pretest?.practiceDate)
    ) {
      arry.push(`practiceTime:off`);
    }
  };
  /** conditions for check with details of pretest  */
  if (pretest && firebase) {
    if (firebase.status === 0) {
      if (pretest?.saleDate > parseInt(localStorage.getItem("now"))) {
        arry.push("waitSale");
      }
      if (pretest.isPurchase === true) {
        arry.push(`isPurchased:${firebase.status}`);
      }
      if (pretest.isSale === true) {
        arry.push(`isSaleTime:${firebase.status}`);
      }
    } else {
      if (firebase.status === 1) {
        openSaleAndAllowTest();
      } else if (firebase.status === 2) {
        arry.push("closeTestingTime");
        if (
          pretest.isPurchase === true &&
          String(pretest?.havePracticeDate) === "true" &&
          IsOverCurrentTime(pretest?.practiceDate)
        ) {
          arry.push(`practiceTime:off`);
        } else if (
          IsDidPretest(pretest) &&
          String(pretest?.havePracticeDate) === "false" &&
          IsOverCurrentTime(
            pretest?.durationPretest?.end + pretest.pretestTime * 60
          )
        ) {
          arry.push(`practiceTime:off`);
        }
        if (NotOverCurrentTime(pretest?.practiceDate)) {
          arry.push(`practiceTime:on`);
        }
      }
    }
    /**  Other conditions for check with firebase  */
    if (firebase.status) {
      if (pretest.isSale === true) {
        arry.push(`isSaleTime:${firebase.status}`);
      }
      if (pretest.isPurchase === true) {
        arry.push(`isPurchased:${firebase.status}`);
      }
      if (pretest.doPretest === true) {
        arry.push(`passTesting:${firebase.status}`);
      }
      if (
        firebase.register &&
        firebase.register[user.id]?.isSendPretest === false
      ) {
        arry.push(`passTesting:false`);
      }
      if (
        firebase.register &&
        firebase.register[user.id]?.isSendPretest === true
      ) {
        // รอบจริง
        arry.push(`passTesting:true`);
      }
    }
    return {
      AllStatus: arry,
    };
  }
  return [];
};

export const suffixNumber = (number) => {
  if (getLocalStorage("_lang") !== "en") {
    return number;
  }
  if (isNaN(parseInt(String(number).at(-1))) === true) {
    return "-";
  }
  if (parseInt(String(number).at(-1)) === 0) {
    return number + "th";
  }
  if (parseInt(String(number).at(-1)) === 1) {
    return number + "st";
  }
  if (parseInt(String(number).at(-1)) === 2) {
    return number + "nd";
  }
  if (parseInt(String(number).at(-1)) === 3) {
    return number + "rd";
  }
  return number + "th";
};

export const getUrlQueryString = (str) => qs.parse(str.split("?")[1]);

export const secondsToHms = (d) => {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = String(h).padStart(2, "0");
  var mDisplay = String(m).padStart(2, "0");
  var sDisplay = String(s).padStart(2, "0");
  return `${hDisplay}:${mDisplay}:${sDisplay}`;
};

export const MathJaxComponent = ({ content, className = "" }) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    // Inject MathJax configuration script
    const scriptConfig = document.createElement("script");
    scriptConfig.type = "text/x-mathjax-config";
    scriptConfig.innerHTML = `
      MathJax.Hub.Config({
        extensions: ["mml2jax.js"], // Ensure MathML is processed
        "HTML-CSS": {
          scale: 100, // Global scaling factor for all math
          linebreaks: { automatic: true }, // Line breaks for responsive display
          availableFonts: ["TeX"],
          showMathMenu: false, // Disable MathJax's context menu
          mtextFontInherit: true, // Ensure that text font is inherited
        },
        SVG: {
          scale: 100, // Scaling factor for SVG output
          linebreaks: { automatic: true }, // Line breaks for responsive display
        },
        mml2jax: {
          useMathMLspacing: true, // Use MathML spacing rules
          preview: "none" // No preview prior to rendering
        },
        MathML: {
          extensions: ["content-mathml.js"], // Support for Content MathML
        },
      });
    `;
    document.head.appendChild(scriptConfig);

    // Inject MathJax script
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "https://cdn.mathjax.org/mathjax/latest/MathJax.js?config=TeX-AMS-MML_HTMLorMML";

    // Handle script load and error
    script.onload = () => {
      setScriptLoaded(true);
      console.log("MathJax script loaded successfully");
      if (window.MathJax) {
        window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub]);
      }
    };

    script.onerror = () => {
      console.error("Failed to load MathJax script");
    };

    document.head.appendChild(script);

    // Cleanup on component unmount
    return () => {
      if (scriptConfig.parentNode === document.head) {
        document.head.removeChild(scriptConfig);
      }
      if (script.parentNode === document.head) {
        document.head.removeChild(script);
      }
    };
  }, []);

  useEffect(() => {
    if (scriptLoaded) {
      const images = document.querySelectorAll("figure.image img");
      images.forEach((img) => {
        img.style.width = "100%";
        img.style.maxWidth = "600px";
      });
    }
  }, [scriptLoaded, content]);

  return (
    <div className={`${styles.mathjaxContainer} ${className}`}>
      {scriptLoaded ? (
        <div dangerouslySetInnerHTML={{ __html: content }} />
      ) : (
        <p>Loading MathJax...</p>
      )}
    </div>
  );
};

export const AudioPlayerComponent = ({ url }) => {
  return (
    <AudioPlayer
      className={styles.player}
      autoPlay={false}
      customVolumeControls={[]}
      showJumpControls={false}
      customAdditionalControls={[]}
      layout="horizontal-reverse"
      src={url}
      onPlay={(e) => console.log("onPlay")}
    />
  );
};
